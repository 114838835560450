<template>
  <EnlazadoInterno v-if="this.$route.fullPath != '/'"/>
  <div :class='{"d-none" : preload || loading , "d-none d-md-none d-sm-none d-md-block d-lg-block d-xl-block": !loading && !preload}' class="bg-azul" id="footer_component">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12">
          <div class="card d-flex align-items-center justify-content-center">
            <div id="logotipo" class="mb-5">
              <a href="https://es.linkedin.com/company/ibancar-world" target="_blank">
              <img width="327" height="85" src="@/assets/img/logo_ibancar.svg" alt="Ibancar" />
              </a>
            </div>
            <div class="card-body">
              <div id="desktop" style="margin-left: -16px;">
                <img width="224" height="77" id="desktop" src="@/assets/img/profeco.png" alt="Ibancar" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12 text-center">
          <div class="card">
            <div id="llamar" class="card-body">
              <a class="btn btn-footer" href="tel:+525555661583">
                <div class="d-flex justify-content-center">
                  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="icon" d="M3.62 8.03393C5.06 10.8691 7.38 13.1933 10.21 14.6359L12.41 12.4319C12.69 12.1514 13.08 12.0713 13.43 12.1815C14.55 12.5521 15.75 12.7525 17 12.7525C17.2652 12.7525 17.5196 12.8581 17.7071 13.0459C17.8946 13.2338 18 13.4886 18 13.7543V17.2607C18 17.5264 17.8946 17.7812 17.7071 17.9691C17.5196 18.157 17.2652 18.2625 17 18.2625C12.4913 18.2625 8.1673 16.4682 4.97918 13.2743C1.79107 10.0803 0 5.74845 0 1.23156C0 0.965858 0.105357 0.711041 0.292893 0.523163C0.48043 0.335285 0.734784 0.229736 1 0.229736H4.5C4.76522 0.229736 5.01957 0.335285 5.20711 0.523163C5.39464 0.711041 5.5 0.965858 5.5 1.23156C5.5 2.48383 5.7 3.68602 6.07 4.80806C6.18 5.1587 6.1 5.54941 5.82 5.82992L3.62 8.03393Z" fill="white" />
                  </svg>
                  <span class="pl-2">55 5566 1583</span>
                </div>
              </a>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <a class="btn btn-footer" href="mailto:hola@ibancar.mx">
                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="icon" d="M2.2 0.404785H19.8C20.3835 0.404785 20.9431 0.64227 21.3556 1.065C21.7682 1.48772 22 2.06106 22 2.65888V16.1835C22 16.7813 21.7682 17.3546 21.3556 17.7774C20.9431 18.2001 20.3835 18.4376 19.8 18.4376H2.2C0.979 18.4376 0 17.4232 0 16.1835V2.65888C0 1.40786 0.979 0.404785 2.2 0.404785ZM11 8.29413L19.8 2.65888H2.2L11 8.29413ZM2.2 16.1835H19.8V5.32999L11 10.954L2.2 5.32999V16.1835Z" fill="white" />
                </svg>
                <span class="pl-2">hola@ibancar.mx</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12">
          <div id="horario" class="card text-center">
            <div class="card-header">
              <p class="card-text text-center">Horario de atención</p>
              <p class="card-text text-center pb-2"><strong>Lunes a Viernes</strong> de <strong>9:00</strong> a <strong>18:00</strong></p>
            </div>
            <div class="card-body pt-3">
              <p class="card-text text-center pb-2"><router-link to="/"> Inicio</router-link></p>
              <p class="card-text text-center"><router-link to="/politica-privacidad" target="_blank">Aviso de privacidad</router-link> | <router-link to="/politica-cookies" target="_blank"> Política de Cookies.</router-link></p>
              <p class="card-test text-center pb-2">
                <a class="text-white" href="https://rpca.profeco.gob.mx/getDoc?p=10053-2023" target="_blank">
                  Contrato registrado ante <strong>PROFECO</strong>
                </a>
              </p>
              <p class="card-test  pb-2">
              <a class="text-white"
                 href="https://rpce.profeco.gob.mx/constancias/000031-2024-0.pdf"
                 target="_blank">
                 Registro público de casas de empeño
              </a>
            </p>
            <p class="card-test  pb-2">
              <a class="text-white" href="https://www.banxico.org.mx/CAT/Calculo_CAT.jsp?" target="_blank">Calculadora CAT Banxico</a>
            </p>
            </div>
            <div class="card-footer text-muted pt-3">
              <p><a href="https://p2p.ibancar.com/" target="_blank">Ibancar P2P transparency site</a></p>
              <p><router-link to="/grupper-platform">information for Grupeer Investors</router-link></p>
              <div id="mobile" class="d-flex flex-row justify-content-center pt-3">
                <img width="134" id="mobile" src="@/assets/img/profeco.png" alt="Ibancar" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body t12">
                <p class="card-text">Préstamos personales desde $12,000 MXN hasta $120,000 MXN, con plazos de 6 a 36 meses. El importe calculado se basa en financiamientos realizados el primer día del mes, con el primer vencimiento el primer día del mes siguiente; en caso de que las fechas sean diferentes, el importe total puede sufrir ligeras variaciones. Sistema de amortización francés.</p>
                <p class="card-text">*Para ejemplo representativo de un monto solicitado de $45,000 MXN a ser pagado en 36 pagos mensuales, la tasa de interés es del 40.8% anual sin IVA y el CAT es de 132.80% (IVA incluido); comisión por apertura financiada de $3,915 MXN sin IVA y costo de servicios de 1.9% mensual sin IVA calculado sobre el total financiado. Fecha de cálculo enero 2025, vigencia 6 meses. CMT (Costo Mensual Totalizado) 11.07% con IVA y CDT (Costo Diario Totalizado) 0.37% con IVA.</p>
                <p class="card-text">*Consulta requisitos de contratación en hola@ibancar.mx</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-gray d-none d-sm-none d-md-block d-lg-block d-xl-block">
    <div class="row align-items-center justify-content-center pt-3">
      <p class="copyright">Copyright © Ibancar LATAM {{ new Date().getFullYear() }}</p>
      <img rel="preconnect" alt="metricool_pixel" src="https://tracker.metricool.com/c3po.jpg?hash=f9cab7082580b380cabbc39b57a755" />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import { useRoute } from "vue-router";
import EnlazadoInterno from "./EnlazadoInterno/EnlazadoInterno.vue";

export default {
  name: "Foot",
  components: {EnlazadoInterno},
  data: function() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  store,
  computed: mapState({
    preload: (state) => state.preload,
    loading: (state) => state.formulario.loading
  }),
  setup(){
    const route = useRoute();

    return {
      route
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  background-color: transparent;
  border: none;
  color: #fff;
}
.card-text {
  text-align: left;
  color: #fff;
}
.bg-gray {
  color: #fff;
  font-size: 10px;
  background: black
}
#horario {
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }
  a:active {
    color: #fff;
  }
}
.btn-footer {
  border: 2px solid #fff;
  width: 216px;
  height: 45px;
  border-radius: 30px;
  font-weight: bolder;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover {
  border: 2px solid #fff;
  width: 216px;
  height: 45px;
  border-radius: 30px;
  font-weight: bolder;
  color: #004680;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover .icon {
  fill: #004680;
}

#btn-linkedin:hover .icon {
  fill: #0a66c2;
}
#btn-facebook:hover .icon {
  fill: #1877f2;
}
.t12 {
  font-size: 12px;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-down(md) {
  .bg-azul {
    z-index: -1;
    background: #004680;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
    margin-top: 0rem;
  }
  #logotipo {
    text-align: center;
  }
  #horario {
    padding-top: 25px;
    .card-header {
      background: none;
      border: none;
      padding: 0;
    }
    .card-body {
      padding: 0;
      border-bottom: 1px solid #fff;
    }
  }
  #desktop {
    display: none;
  }
  .copyright {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-between(lg, xl) {
  .bg-azul {
    z-index: -1;
    background: #004680;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
  }
  #logotipo {
    text-align: left;
  }
  #horario {
    .card-header {
      background: none;
      border: none;
      padding: 0;
    }
    .card-body {
      padding: 0;
      border-bottom: 1px solid #fff;
    }
    #mobile {
      display: none;
    }
  }
}
</style>