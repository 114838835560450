<template>
    <div :class='{"d-none" : preload , "d-md-block d-lg-block d-xl-block": !preload}' class="section my-5 mx-0  p-3" v-if="cards && cards.length > 0">
        <p class="text-center mb-5 text__blue text__bold title mb-0 subtitlep" >Préstamos por tu auto</p>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-12 mb-4 mb-md-4" v-for="card in cards" :key="card.title">
                    <div class="card mx-0 mx-md-3 p-0 p-md-2 border__blue">
                        <div class="card-body" @click="changeRoute(card.path)">
                            <div class="d-flex justify-content-between text__orange mb-2 mb-md-3">
                                <span class="text__bold subtitle">
                                    {{ card.title }}
                                </span>
                                <span>
                                    <img src="@/assets/img/icons/chevron-right-orange.svg" alt="Ibancar"/>
                                </span>
                            </div>
                            <p class="card-text mb-0 text">
                                <span v-for="text in getText(card)" :class="{'text__bold': text.bold}" :key="text.index">
                                    {{ text.text }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

    const empenoAutoCard = {
        title: 'Empeños de auto',
        text: 'Descubre la mejor alternativa a ¡empeños de auto con Ibancar!',
        path: '/empeno-de-autos-en-ibancar',
        bold: 'empeños de auto'
    };
    const prestamoSobreAuto = {
        title: 'Préstamos sobre auto',
        text: 'En Ibancar, te ofrecemos préstamos sobre auto rápidos y fáciles.',
        path: '/prestamos-sobre-auto-en-ibancar',
        bold: 'préstamos sobre auto'
    };
    const prestamosConGarantiaDeAuto = {
        title: 'Préstamos con garantía de auto',
        text: 'Te otorgamos un rápido préstamo en cuestión de un día.',
        bold: 'rápido préstamo',
        path: '/prestamo-garantia-auto'
    };
    const prestamosConGarantiaDeAutoMorelos = {
        title: 'Préstamos con garantía de auto Morelos',
        text: 'Resuelve tus emergencias con préstamos en Morelos.',
        bold: 'Morelos',
        path: '/prestamos-garantia-auto-morelos'
    };
    const prestamosConGarantiaDeAutoPuebla = {
        title: 'Préstamos con garantía de auto Puebla',
        text: 'Obtén liquidez rápida con préstamos con garantía de auto en Puebla.',
        bold: 'Puebla',
        path: '/prestamos-garantia-auto-puebla'
    };
    const prestamosConGarantiaDeAutoQueretaro = {
        title: 'Préstamos con garantía de auto Querétaro',
        text: 'Descubre los préstamos con garantía de auto en Querétaro.',
        bold: 'Querétaro',
        path: '/prestamos-garantia-auto-queretaro'
    };
    const prestamosConGarantiaDeAutoEdomex = {
        title: 'Préstamos con garantía de auto Edomex',
        text: 'Accede a préstamos con garantía de auto en el Edomex de forma rápida y segura.',
        bold: 'Edomex',
        path: '/prestamos-garantia-auto-edomex'
    };
    const empenoAutoEnMexico = {
        title: 'Empeños de autos en México',
        text: 'Empeño de autos en México, ventajas de empeñar tu auto',
        path: '/empeno-de-auto-en-mexico',
        bold: 'Empeño de autos en México'
    };
    const empenaTuAutoYContinuaUsandolo = {
        title: 'Empeña tu auto y continua usándolo',
        text: 'Descubre cómo empeñar tu vehículo y seguir disfrutándolo',
        path: '/empena-tu-auto-y-continua-usandolo',
        bold: 'empeñar tu vehículo'
    };
    const prestamosConFacturaDeAuto = {
        title: 'Prestamos con la factura de tu auto',
        text: 'Consigue efectivo al instante con préstamos respaldados por la factura de tu auto.',
        path: '/prestamo-factura-auto',
        bold: 'prestamos con la factura de tu auto'
    };
    const prestamosGarantiaPrendaria= {
        title: 'Óbten seguridad financiera con el préstamo con garantía prendaria en Ibancar',
        text: 'Préstamo con garantía prendaria',
        path: '/prestamo-garantia-prendaria',
        bold: 'préstamo con garantía prendaria'
    };
    const prestamosGarantiaAutomotriz= {
        title: 'Préstamos con garantía automotriz',
        text: 'Préstamos con garantía automotriz: Tu solución financiera en momentos difíciles',
        path: '/prestamo-garantia-automotriz',
        bold: 'préstamo con garantía automotriz'
    };
    const prestamosPorFacturaDeAuto = {
        title: 'Prestamos por factura de carro',
        text: 'Solución financiera rapida y segura',
        path: '/prestamo-factura-auto',
        bold: 'prestamos por la factura de tu auto'
    };
    const prestamoConGarantiaDeAutoSinDejarlo= {
        title: 'Préstamo con garantía de auto sin dejarlo',
        text: 'Préstamos con garantía de auto sin dejarlo. La solucion financiera que necesitas',
        path: '/prestamo-con-garantia-de-auto-sin-dejarlo',
        bold: 'La solución financiera que necesitas'
    };

    const dondeEmpenarTuCarroEnMexico = {
        title: 'Guía completa para empeñar tu carro en México.',
        text: 'Cómo y dónde empeñar tu carro por efectivo en México',
        path: '/guia-completa-empenar-carro-en-mexico',
        bold: 'Guía completa para empeñar tu carro en México.'
    };

    const prestamoSobreVehiculo = {
        title: 'Préstamo sobre vehículo',
        text: 'Tú solución financiera inmediata',
        path: '/prestamo-sobre-vehiculo',
        bold: 'Tú solución financiera inmediata'
    };

    const prestamoPorAutomovilConIbancar = {
        title: 'Obtén tu préstamo por tu automóvil con Ibancar México.',
        text: 'Obtén tu préstamo por tu automóvil con Ibancar México',
        path: '/obten-tu-prestamo-por-automovil-con-ibancar',
        bold: 'Obtén tu préstamo por tu automóvil con Ibancar México'
    };

    const consigueDineroPorTuCarroUsado = {
        title: 'Consigue dinero por tu carro usado en México sin tener que venderlo.',
        text: 'Consigue dinero por tu carro usado en México sin tener que venderlo.',
        path: '/consigue-dinero-por-tu-carro-usado',
        bold: 'Consigue dinero por tu carro usado en México sin tener que venderlo.'
    };

    const consigueTuCreditoConGarantiaPrendaria = {
        title: 'Consigue tu crédito en México con garantía prendaria.',
        text: 'Consigue tu crédito en México con garantía prendaria.',
        path: '/consigue-tu-credito-con-garantia-prendaria',
        bold: 'Consigue tu crédito en México con garantía prendaria.'
    };

    const comoEmpenarLosPapelesDeTuAuto = {
        title: 'Cómo empeñar los papeles de tu auto en México: Paso a paso.',
        text: 'Cómo empeñar los papeles de tu auto en México: Paso a paso.',
        path: '/como-empenar-los-papeles-de-tu-auto-en-mexico',
        bold: 'Cómo empeñar los papeles de tu auto en México: Paso a paso.'
    };

    const prestamosDeDineroRapido = {
        title: 'Consigue préstamos de dinero rápido en Ibancar.',
        text: 'Consigue préstamos de dinero rápido en Ibancar.',
        path: '/prestamos-de-dinero-rapido',
        bold: ''
    };

    const prestamoRapido = {
        title: 'Prestamo rápido',
        text: 'Obtén un préstamo rápido en México con Ibancar.',
        path: '/prestamo-rapido',
        bold: 'préstamo rápido'
    };

    const empenosDeCarrosLoQueDebesSaber = {
        title: 'Empeños de coches: cómo funcionan, ventajas y cómo hacerlo.',
        text: 'Empeños de coches: cómo funcionan, ventajas y cómo hacerlo.',
        path: '/empenos-carros-lo-que-debes-saber',
        bold: 'Empeños de coches: cómo funcionan, ventajas y cómo hacerlo.'
    };

    const empenoFacil = {
        title: 'Empeño fácil: funcionamiento,ventajas y proceso.',
        text: 'Empeño fácil: funcionamiento,ventajas y proceso.',
        path: '/empeno-facil',
        bold: 'Empeño fácil: funcionamiento,ventajas y proceso.'
    };

    const prestamosPersonalesUrgentes = {
        title: '¿Necesitas préstamos personales urgentes? Así puedes conseguirlo',
        text: '¿Necesitas préstamos personales urgentes? Así puedes conseguirlo',
        path: '/prestamos-personales-urgentes',
        bold: ''
    };

    const prestamosSinBuro = {
        title: 'Préstamos sin buró: ¡Obtén financiación con tu auto como garantía!',
        text: 'Si necesitas un préstamo y tienes mal buró, descubre nuestro préstamo de Ibancar.',
        path: '/prestamos-sin-buro',
        bold: 'tienes mal buró'
    };

    const prestamosConfiablesYRapidos = {
        title: 'Préstamos confiables: tu tranquilidad financiera comienza aquí',
        text: 'Préstamos confiables: tu tranquilidad financiera comienza aquí',
        path: '/prestamos-confiables-y-rapidos',
        bold: ''
    };

    const prestamosParaEmprendedores = {
        title: 'Préstamos para emprendedores: ¡Impulsa tu negocio hoy!',
        text: 'Préstamos para emprendedores: ¡Impulsa tu negocio hoy!',
        path: '/prestamos-para-emprendedores',
        bold: ''
    };

    const prestamosConMalHistorial = {
        title: 'Préstamos con mal historial crediticio: Ibancar te ayuda',
        text: '¿Tienes un mal historial crediticio? En Ibancar ofrecemos préstamos accesibles en México.',
        path: '/prestamos-con-mal-historial',
        bold: 'mal historial'
    };

    const prestamosEnLineaPorTuCarro = {
        title: 'Préstamos en línea por tu carro: rápidos y seguros',
        text: '¡Fácil y rápido! Consigue tu préstamo hoy',
        path: '/prestamos-en-linea-por-tu-carro',
        bold: '¡Fácil y rápido! Consigue tu préstamo hoy'
    };

    const prestamosEnLineaAlInstante = {
        title: 'Préstamos en línea al instante con mi auto',
        text: 'Obtén dinero rápido con tu carro como aval',
        path: '/prestamos-en-linea-al-instante',
        bold: ''
    };



    export default {
        name: "EnlazadoInterno",
        data() {
            return {
                cards: [],
                index: 0
            }
        },

        store,
        computed: mapState({
            preload: (state) => state.preload,
        }),
        methods: {
            changeRoute: function( path ){
                this.$router.push({
                    path: path ? path : '#',
                    hash: null,
                });  
            },
            getText: function( card ){
                const { text, bold } = card
                const textArray = new Array();

                if(bold){
                    const positionBold = text.indexOf(bold);
                    const lengthBold = bold.length;

                    if( positionBold != -1 ){
                        // Si hay bold
                        if(positionBold > 0){
                            textArray.push({bold: false,text: text.substring(0, positionBold)})
                        }

                        textArray.push({bold: true,text: text.substring(positionBold, positionBold + lengthBold)})
                        textArray.push({bold: false,text: text.substring(positionBold + lengthBold, text.length)})

                        return textArray;
                    }
                }
                
                textArray.push({bold: false,text: text})
                return textArray;
            },
            fillCards: function (path) {
            
                switch( path ){
                    case '/':
                        this.cards = [
                            prestamosConGarantiaDeAuto,
                            empenoAutoCard,
                            prestamoSobreAuto,
                            prestamoRapido
                        ]
                        break;
                    // Empeños Parent
                    case '/empeno-de-autos-en-ibancar':
                        this.cards = [
                            empenoAutoEnMexico,
                            empenaTuAutoYContinuaUsandolo,
                            dondeEmpenarTuCarroEnMexico,
                            comoEmpenarLosPapelesDeTuAuto,
                            empenosDeCarrosLoQueDebesSaber,
                            empenoFacil
                        ]
                        break;
                    // Empeños Childs
                    case '/empeno-de-auto-en-mexico':
                        this.cards = [
                            empenoAutoEnMexico,
                        ]
                        break;
                    case '/empena-tu-auto-y-continua-usandolo':
                        this.cards = [
                            empenoAutoEnMexico,
                        ]
                        break;
                    case '/guia-completa-empenar-carro-en-mexico':
                        this.cards = [
                            empenoAutoEnMexico,
                        ]
                        break;
                    case '/como-empenar-los-papeles-de-tu-auto-en-mexico':
                        this.cards = [
                            empenoAutoEnMexico,
                        ]
                        break;
                    case '/empenos-carros-lo-que-debes-saber':
                        this.cards = [
                            empenoAutoEnMexico,
                        ]
                        break;
                    case '/empeno-facil':
                        this.cards = [
                            empenoAutoEnMexico,
                        ]
                        break;      
                    // Préstamos Parent
                    case '/prestamos-sobre-auto-en-ibancar':
                        this.cards = [
                            prestamosConGarantiaDeAuto,
                            prestamosConFacturaDeAuto,
                            prestamosGarantiaPrendaria,
                            prestamosGarantiaAutomotriz,
                            prestamosPorFacturaDeAuto,
                            prestamoSobreVehiculo,
                            prestamosConMalHistorial,
                            consigueTuCreditoConGarantiaPrendaria,
                            prestamoConGarantiaDeAutoSinDejarlo,
                            prestamosEnLineaPorTuCarro,
                            prestamoPorAutomovilConIbancar,
                            consigueDineroPorTuCarroUsado,
                            prestamosSinBuro,
                        ]
                        break;
                    // Préstamos Childs
                    case '/prestamo-por-factura-auto':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamo-garantia-prendaria':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamo-garantia-automotriz':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamo-factura-auto':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamo-con-garantia-de-auto-sin-dejarlo':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamo-sobre-vehiculo':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/obten-tu-prestamo-por-automovil-con-ibancar':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/consigue-dinero-por-tu-carro-usado':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/consigue-tu-credito-con-garantia-prendaria':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamos-sin-buro':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamos-con-mal-historial':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    case '/prestamos-en-linea-por-tu-carro':
                        this.cards = [
                            prestamoSobreAuto,
                        ]
                        break;
                    // Préstamos Rápidos: Parent
                    case '/prestamo-rapido':
                        this.cards = [
                            prestamosEnLineaAlInstante,
                            prestamosDeDineroRapido,
                            prestamosPersonalesUrgentes,
                            prestamosParaEmprendedores,
                            prestamosConfiablesYRapidos
                        ];
                        break;
                    // Préstamos Rápidos: Childs
                    case '/prestamos-en-linea-al-instante':
                        this.cards = [
                            prestamoRapido,
                        ]
                        break;
                    case '/prestamos-de-dinero-rapido':
                        this.cards = [
                            prestamoRapido,
                        ]
                        break;
                    case '/prestamos-personales-urgentes':
                        this.cards = [
                            prestamoRapido,
                        ]
                        break;
                    case '/prestamos-para-emprendedores':
                        this.cards = [
                            prestamoRapido,
                        ]
                        break;
                    case '/prestamos-confiables-y-rapidos':
                        this.cards = [
                            prestamoRapido,
                        ]
                        break;

                    // Préstamos Garantía Auto: Parent
                    case '/prestamo-garantia-auto':
                        this.cards = [
                            prestamosConGarantiaDeAutoPuebla,
                            prestamosConGarantiaDeAutoQueretaro,
                            prestamosConGarantiaDeAutoEdomex,
                            prestamosConGarantiaDeAutoMorelos
                        ]
                        break; 
                    // Préstamos Garantía Auto: Childs
                    case '/prestamos-garantia-auto-puebla':
                        this.cards = [
                            prestamosConGarantiaDeAuto,
                        ]
                        break; 
                    case '/prestamos-garantia-auto-queretaro':
                        this.cards = [
                            prestamosConGarantiaDeAuto,
                        ]
                        break;
                    case '/prestamos-garantia-auto-edomex':
                        this.cards = [
                            prestamosConGarantiaDeAuto,
                        ]
                        break;
                    case '/prestamos-garantia-auto-morelos':
                        this.cards = [
                            prestamosConGarantiaDeAuto,
                        ]
                        break; 
                    // Default
                    default:
                        this.cards = [
                            empenoAutoCard,
                            prestamoSobreAuto,
                            prestamosConGarantiaDeAuto,
                        ];
                        break;
                }
            }
        },
        watch: {
            '$route': function( value ){
                const { path } = value
                this.fillCards( path )
            }
        },
        mounted() {
            const { path } = this.$route
            this.fillCards( path )
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/styles/variables.scss';
    @import 'bootstrap/scss/_functions.scss';
    @import 'bootstrap/scss/_variables.scss';
    @import 'bootstrap/scss/_mixins.scss';

    .subtitlep {
        font-size: 31px;
    }

    .card-text span {
        font-size: 15px;
    }
    
    .section{
        font-family: "Montserrat";
    }

    p.title {
        font-size: 31px;
    }

    .subtitle{
        font-size: 24px;
        line-height: 28px;
    }

    .text{
        &__blue{
            color: $blue;
        }
        &__bold{
            font-family: "Montserrat-Bold";
            font-weight: bold;
        }
        &__orange{
            color: $orange;
        }
        font-size: 18px;
        line-height: 21px;
    }

    .subtitle{
        font-size: 24px;
        line-height: 26px;
    }

    .border{
        &__blue{
            border-color: $blue;
        }
    }

    .card{
        height: 100%;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 0.75rem;
        .card-body{
            cursor: pointer;
        }
    }

    @include media-breakpoint-up(md){
        p.title {
        font-size: 31px;
    }
    }
    
    @include media-breakpoint-down(sm) {
        .title{
            font-size: 25px;
            line-height: 22px;
        }

        .subtitlep{
            font-size: 20px !important;
        }
    }

    @include media-breakpoint-down(md){
        .subtitle {
            font-size: 18px;
            line-height: 18px;
        }

        .text {
            font-size: 13px;
            line-height: 15px;
        }
    }

    @include media-breakpoint-only(md) {
        .title{
            font-size: 24px;
            line-height: 28px;
        }
    }

    @include media-breakpoint-only(lg) {
        .title{
            font-size: 30px;
            line-height: 35px;
        }
    }

</style>